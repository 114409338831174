.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

div.table-container {
  width: 100%;
  overflow-x: auto;
}

table.no-wrap th,
table.no-wrap td {
  white-space: nowrap;
  text-align: left;
}